<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('thirdParty_appealContent.appealContent') }}
    </div>
    <ag-grid
      ref="appealGrid"
      style="width:100%;height:360px;"
      edit-type="fullRow"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    />
    <div slot="footer">
      <el-button
        @click="handleDialogClosed"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  get: '/jlr/tt/selfExaminationData/get',
  download: '/system/attachment/download',
  update: '/jlr/tt/selfExaminationData/update'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleSaveClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" icon="el-icon-circle-check" style="font-size:14px;" @click="handleSaveClick(params)" />
      </el-tooltip>
    </div>`
})

/** 自定义单元格按钮 附件下载 */
const Attachment = Vue.extend({
  inject: ['downloadFile'],
  template: `<div style="height:100%;">
      <el-tooltip effect="light" :content="$t('dealer_reviewConfirm.attachment')">
        <el-button type="text" icon="el-icon-document" @click="downloadFile(params.data)"
          v-if="params.data.attachment && params.data.attachment.id">
          {{ params.data.attachment ? params.data.attachment.fileName + '.' + params.data.attachment.fileType : ''}}
        </el-button>
      </el-tooltip>
    </div>`
})

export default {
  name: 'Appeal',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      searchModel: {},
      tableData: [],
      headInfo: {},
      gridOptions: {},
      isShowUploader: false,
      frameworkComponents: {}
    }
  },
  inject: ['handleDataSearch'],
  provide () {
    return {
      handleSaveClick: this.handleSaveClick,
      downloadFile: this.downloadFile
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_reviewConfirm.appealContent'),
          field: 'appealContent',
          minWidth: 380
        },
        {
          headerName: this.$t('dealer_reviewConfirm.attachment'),
          cellRenderer: 'Attachment',
          minWidth: 200
        },
        {
          headerName: this.$t('thirdParty_appealContent.thirdPartyReply'),
          field: 'reply',
          minWidth: 380,
          editable: true,
          cellEditorParams: {
            rules: { required: true, message: this.$t('validate.isRequired') }
          }
        },
        {
          headerName: this.$t('thirdParty_appealContent.finalConfirm'),
          field: 'finalResult',
          minWidth: 140,
          editable: true,
          type: 'Select',
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_final_result').map(item => {
                return { value: item.value, label: item.label }
              })
            },
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueFormatter: params => this.$getDictLabel({ type: 'tt_final_result', value: params.value })
        },
        {
          headerName: this.$t('field.operation'),
          width: 68,
          pinned: 'right',
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation, Attachment: Attachment }
  },
  methods: {
    handleDialogOpen () {
      this.$nextTick(() => { this.$refs.appealGrid.redrawGrid(this.columnDefs) })
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.headInfo = respData
          this.tableData = respData.reviewConfirmList
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleDialogClosed () {
      this.tableData = [{}]
      this.currentShowFlag = false
    },
    downloadFile (rowData) {
      this.$utils.fileSaveAs(BASEURL.download, { id: rowData.attachment.id })
    },
    handleSaveClick (row) {
      this.gridOptions.api.stopEditing()
      this.$refs.appealGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.headInfo)
          submitModel.reviewConfirmList = this.$_.cloneDeep(this.tableData)
          this.$axios
            .post(BASEURL.update, submitModel)
            .then(resp => {
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
